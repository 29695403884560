<template>
  <div class="box">
    <vue-esign ref="esign" :width="400" :height="800" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
               :bgColor.sync="bgColor" />
    <div style="transform: rotate(90deg);margin-top: -95%;margin-left: -130%;">
      <el-button  type="danger" @click="handleReset">重置签名</el-button>
      <el-button type="success" @click="handleGenerate">提交签名</el-button>
    </div>
<!--    <img :src="resultImg" alt="">-->
  </div>
</template>

<script>
import {Encrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";

export default {
  name: "",
  data() {
    return {
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
    }
  },
  created() {

  },
  methods: {
    handleReset() {
      this.$refs.esign.reset();
    },
    handleGenerate() {
      this.$refs.esign
          .generate({ format: "png", quality: 0.8 })
          .then((res) => {
            this.resultImg = res;
            this.axios.post('/secrecySignature/saveSignature', (response) => {
              if (response.obj){

                this.$router.push({
                  name: 'File_index',
                  params: {
                    name_url: this.resultImg
                  }
                });
              }
            },{
              name_url: this.resultImg
            })
          })
          .catch((err) => {
            console.error(err);
            // alert("生成图片失败：" + err.message);
            ElMessage.error('签名失败!')
          });
    }
  }
}
</script>

<style scoped>
.box {
  width: 70%;
  height: 50vh;
  border: 1px solid red;
  margin-left: 25%;
  margin-top: 20%;
  //overflow: hidden;
}

</style>